import { timeFormat } from '../utils';
import { DELIVER_TYPE_ENUM, PICKUP_TYPE_ENUM, URGENT_ENUM } from '../constant';

const DetailFromInfo = ({ handling }) => {
  const arr = handling ? [{
    label: '是否需要装卸:',
    prop: 'handling',
    ENUM: URGENT_ENUM,
  }, {
    label: '是否外包装卸:',
    prop: 'outsourceHandling',
    ENUM: URGENT_ENUM,
  }] : [{
    label: '是否需要装卸:',
    prop: 'handling',
    ENUM: URGENT_ENUM,
  }];
  return [{
    label: '出库单号：',
    prop: 'deliverOrderNo',
  }, {
    label: '货主：',
    prop: 'shipperName',
  }, {
    label: '出库单类型：',
    prop: 'deliverType',
    ENUM: DELIVER_TYPE_ENUM,
  }, {
    label: '提货方式：',
    prop: 'pickupType',
    ENUM: PICKUP_TYPE_ENUM,
  }, {
    label: '提货单号：',
    prop: 'ladingOrderNo',
  }, {
    label: '站点信息：',
    prop: 'stationName',
  }, {
    label: '联系人：',
    prop: 'stationContact',
  }, {
    label: '联系人电话：',
    prop: 'stationTelephone',
  }, {
    label: '配销分类：',
    prop: 'distributeTypeCode',
  },
  ...arr,
  {
    label: '收货地址：',
    prop: 'stationAddress',
  }, {
    label: '创建时间：',
    prop: 'createTime',
    timeFormat,
  }, {
    label: '预约提货时间：',
    prop: 'bookingTime',
    timeFormat,
  }, {
    label: '实际出库时间：',
    prop: 'deliverTime',
    timeFormat,
  }, {
    label: '备注：',
    prop: 'remark',
  }];
};
export default DetailFromInfo;
