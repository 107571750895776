<template>
  <!-- <div id="storSet"> -->
  <nh-dialog
    v-model="visible"
    title="添加货品"
    :before-close="handleClose"
    :track="{
      name_zh: `出库管理/${nameZh}/抽屉-${title}/弹窗-添加货品`,
      trackName: $route.path
    }"
  >
    <pl-block>
      <PlForm
        v-model="formData"
        mode="search"
        :fields="serchFields"
        :form-props="{
          labelWidth: 'auto',
        }"
        @submit="handleQueryFrom"
        @reset="handleQueryFrom"
      />
    </pl-block>

    <pl-block>
      <PlTable
        ref="goods"
        v-model:pagination="pagination"
        :loading="loading.loadGoodsByWms"
        :data="goods"
        :columns="TABLECOLUMN"
        height="300px"
        @update:pagination="handlePaginationChange"
        @selection-change="handleSelectionChange"
      />
    </pl-block>
    <template #footer>
      <div class="dialog-footer">
        <nh-button
          type="primary"
          :loading="loading.headleSubmitForm"
          @click="headleConfirm"
        >
          添加货品
        </nh-button>
        <nh-button @click="handleClose">
          取消
        </nh-button>
      </div>
    </template>
  </nh-dialog>
  <!-- </div> -->
</template>
<script>
import loadingMixin from '@thales/loading';
import { loadGoodsByWms } from '../api';
import { TABLECOLUMN, serchFields } from './fileds';

export default {
  name: 'AddGood',
  components: {},
  filters: {},
  mixins: [loadingMixin],
  inject: ['nameZh'],
  props: {
    addedGoods: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  emits: ['added'],

  data() {
    return {
      serchFields,
      TABLECOLUMN,
      goods: [],
      formData: {},
      loading: {
        loadGoodsByWms: false,
      },
      addVisible: false,
      editVisible: false,
      previewVisible: false,
      editid: '',
      warehouseZonelist: [],
      visible: false,
      shipperId: '',
      searchForm: {
        abstractZoneCode: null,
        abstractZoneName: null,
        typeCode: null,
      },
      rules: {
        abstractZoneCode: [{ required: false, message: '请输入货品编码', trigger: 'blur' }],
        abstractZoneName: [{ required: false, message: '请输入货品名称', trigger: 'blur' }],
        typeCode: [{ required: false, message: '请选择温层', trigger: 'change' }],
      },
      selectGoods: [],
    };
  },
  computed: {},
  created() {},
  methods: {
    showDialog(shipperId) {
      this.shipperId = shipperId;
      this.pagination.page = 1;
      this.loadGoodsByWms();
      this.visible = true;
    },
    headleConfirm() {
      if (this.selectGoods.length > 0) {
        // 深拷贝 防止 添加同一个 商品后，会导致两个商品的数量会同时修改问题
        this.$emit('added', JSON.parse(JSON.stringify(this.selectGoods)));
        this.handleClose();
      } else {
        this.$message({
          message: '请至少选择一行后再添加',
          type: 'warning',
        });
      }
    },
    handleClose() {
      this.$refs.goods.elTable.clearSelection();
      this.visible = false;
    },
    handleQueryFrom(data) {
      this.pagination.page = 1;
      this.formData = data;
      this.loadGoodsByWms();
    },
    handlePaginationChange() {
      this.loadGoodsByWms();
    },
    handleSelectionChange(selection) {
      this.selectGoods = selection;
    },
    async loadGoodsByWms() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await loadGoodsByWms(pagination, {
        ...this.formData,
        shipperId: this.shipperId,
      });
      this.goods = result.records;
      this.pagination.total = result.total;
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
