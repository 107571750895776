import { FormType } from '@/constant/form';
import { YMDHMS } from '@/constant/timeFormat';
import Table from '@/constant/table';
import {
  SOURCE,
  PICKUP_TYPE,
  DELIVER_STSTUS,
  DELIVER_STSTUS_ENUM,
  DELIVER_TYPE,
  URGENT,
  DELIVER_TYPE_ENUM,
} from './constant';
import { timeFormat } from './utils';

const { TIME_COLUMN_WIDTH } = Table;

export const TABLECOLUMN = [
  {
    type: 'selection',
    width: 55,
  }, {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '出库单号',
    prop: 'deliverOrderNo',
    minWidth: 230,
  }, {
    label: '提货单号',
    prop: 'ladingOrderNo',
    minWidth: 160,
  }, {
    label: '波次号',
    prop: 'waveNo',
    minWidth: 160,
  }, {
    label: '订单类型',
    prop: 'deliverType',
    minWidth: 120,
    formatter: (row) => DELIVER_TYPE_ENUM[row.deliverType],
  }, {
    label: '状态',
    prop: 'deliverStatus',
    minWidth: 80,
    formatter: (row) => DELIVER_STSTUS_ENUM[row.deliverStatus],

  }, {
    label: '货主',
    prop: 'shipperName',
    minWidth: 120,
  }, {
    label: '站点名称',
    prop: 'stationName',
    minWidth: 140,
  }, {
    label: '线路编号',
    prop: 'lineId',
    minWidth: 160,
  }, {
    label: '预约总数量(默认单位)',
    prop: 'defaultNum',
    minWidth: 100,
  }, {
    label: '预约总重量(kg)',
    prop: 'bookingTotalWeight',
    minWidth: 160,
  }, {
    label: '预约总体积(m³)',
    prop: 'bookingTotalVolume',
    minWidth: 160,
  }, {
    label: '总SKU数',
    prop: 'skuNum',
    minWidth: 160,
  }, {
    label: '线路名称',
    prop: 'lineName',
    minWidth: 140,
  }, {
    label: '订单创建时间',
    prop: 'createTime',
    minWidth: TIME_COLUMN_WIDTH,
    formatter: (row) => timeFormat(row.createTime),
  }, {
    label: '预约提货时间',
    prop: 'bookingTime',
    minWidth: TIME_COLUMN_WIDTH,
    formatter: (row) => timeFormat(row.bookingTime),
  }, {
    label: '实际出库时间',
    prop: 'deliverTime',
    minWidth: 160,
    formatter: (row) => timeFormat(row.deliverTime),
  }, {
    label: '外部单号',
    prop: 'externalOrderNo',
    minWidth: 160,
  }, {
    label: '客户订单号',
    prop: 'customerOrderNo',
    minWidth: 160,
  }, {
    label: '配销分类',
    prop: 'distributeTypeCode',
    minWidth: 160,
  }, {
    label: '目的城市',
    prop: 'stationCity',
    minWidth: 160,
  }, {
    label: '操作',
    prop: 'action',
    fixed: 'right',
    width: 120,
  }];

export const serchFields = (DISTRIBUTION) => [
  {
    label: '出库单号',
    prop: 'deliverOrderNo',
    component: FormType.INPUT,
  }, {
    label: '出库单类型',
    prop: 'deliverType',
    component: FormType.SELECT,
    options: DELIVER_TYPE,
  }, {
    label: '波次号',
    prop: 'waveNo',
    component: FormType.INPUT,
  }, {
    label: '货主',
    prop: 'shipperId',
  }, {
    label: '外部关联单号',
    prop: 'externalOrderNo',
    component: FormType.INPUT,
  }, {
    label: '客户订单号',
    prop: 'customerOrderNo',
    component: FormType.INPUT,
  }, {
    label: '配送站点',
    prop: 'stationName',
    component: FormType.INPUT,
  }, {
    label: '线路',
    prop: 'lineName',
    component: FormType.INPUT,
  }, {
    label: '提货单号',
    prop: 'ladingOrderNo',
    component: FormType.INPUT,
  }, {
    label: '提货方式',
    prop: 'pickupType',
    component: FormType.SELECT,
    options: PICKUP_TYPE,
  }, {
    label: '订单来源',
    prop: 'source',
    component: FormType.SELECT,
    options: SOURCE,
  }, {
    label: '是否加急',
    prop: 'urgent',
    component: FormType.SELECT,
    options: URGENT,
  }, {
    label: '出库单状态',
    prop: 'deliverStatus',
    component: FormType.SELECT,
    options: DELIVER_STSTUS,
  }, {
    label: '目的城市',
    prop: 'city',
    component: FormType.SELECT,
    options: DELIVER_STSTUS,
  }, {
    label: '配销分类',
    prop: 'distributeTypeCode',
    component: FormType.SELECT,
    options: DISTRIBUTION,
    componentAttrs: {
      multiple: true,
      // 'collapse-tags': true,
    },
  }, {
    label: '创建时间',
    prop: 'createTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      default: [],
      valueFormat: YMDHMS,
    },
  }, {
    label: '预约提货时间',
    prop: 'bookingTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      default: [],
      valueFormat: YMDHMS,
    },
  },
];
