<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields(DISTRIBUTION)"
      @submit="handleQueryFrom"
      @reset="handleQueryFrom"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="formData.shipperId"
        />
      </template>
      <template #city>
        <el-cascader
          v-model="formData.city"
          placeholder="请选择目的城市"
          :options="cityList"
          :props="{ multiple: true }"
          :show-all-levels="false"
          collapse-tags
          filterable
          clearable
        />
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.loadDeliverOrderPage"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @selection-change="handleSelectionChange"
      @update:pagination="loadDeliverOrderPage"
    >
      <template #tableHeaderLeft>
        <nh-button
          type="primary"
          :track="{
            trackName: '出库管理/出库单管理/[新建]'
          }"
          @click="headleAddOutboundOrder"
        >
          新增
        </nh-button>
        <nh-button
          type="grey"
          :track="{
            trackName: '出库管理/出库单管理/[创建波次]'
          }"
          @click="handleBatchAddWave"
        >
          创建波次
        </nh-button>
        <nh-button
          type="grey"
          :track="{
            trackName: '出库管理/出库单管理/[批量创建波次]'
          }"
          @click="handleAddWave"
        >
          批量创建波次
        </nh-button>
        <nh-button
          type="grey"
          :track="{
            trackName: '出库管理/出库单管理/[打印送货单]'
          }"
          @click="handlePrintDelivery"
        >
          打印送货单
        </nh-button>
        <nh-button
          type="grey"
          :track="{
            trackName: '出库管理/出库单管理/[订单取消]'
          }"
          :loading="loading.handleDeliverOrderClose"
          @click="handleDeliverOrderClose"
        >
          订单取消
        </nh-button>
        <nh-button
          type="grey"
          :track="{
            trackName: '出库管理/出库单管理/[订单加急]'
          }"
          :loading="loading.orderUrgent"
          @click="handleOrderUrgent(1)"
        >
          订单加急
        </nh-button>
        <nh-button
          type="grey"
          :track="{
            trackName: '出库管理/出库单管理/[取消加急]'
          }"
          :loading="loading.orderUrgent"
          @click="handleOrderUrgent(2)"
        >
          取消加急
        </nh-button>
      </template>
      <template #tableHeaderRight>
        <nh-button
          type="grey"
          @click="handleLadingOrderImport"
        >
          更新排线计划
        </nh-button>
        <nh-button
          type="grey"
          :track="{
            trackName: '出库管理/出库单管理/[批量导入]'
          }"
          @click="handleShowBatchImport"
        >
          批量导入
        </nh-button>
        <nh-button
          type="grey"
          :track="{
            trackName: '出库管理/出库单管理/[导出]'
          }"
          @click="handleExport"
        >
          导出
        </nh-button>
      </template>
      <template #deliverOrderNo="{row}">
        <nh-button
          type="text"
          @click="headleOndetail(row.id)"
        >
          {{ row.deliverOrderNo }}
        </nh-button>
        <span class="tag" :style="{background:row.urgent?'#CC0707':'#07CC6D'}" />
        <span>{{ row.urgent?'加急':'正常' }}</span>
      </template>
      <template #distributeTypeCode="{row}">
        <span>{{ DISTRIBUTION.find((dis => dis.value === row.distributeTypeCode))?.label }}</span>
      </template>
      <template #action="{row}">
        <nh-button
          v-if="showEditButton(row)"
          type="text"
          @click="headleEditDeliverOrder(row)"
        >
          编辑
        </nh-button>
        <nh-button
          v-if="row.deliverStatus==='IN_PRODUCTION' && row.waveOrderStatus==='PICK_COMPLETED'"
          type="text"
          @click="headleintercept(row.id)"
        >
          拦截
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <AddWaveDialog ref="addWaveDialog" @createSuccess="handleAddWaveSuccess" />
  <nh-data-import
    ref="batchImport"
    :err-msg-list="errMsgList"
    :is-upload="isUpload"
    :quantity-limit="3000"
    :data-amount="allLadingOrder.length"
    :track="{
      name_zh: '出库管理/出库单管理/弹窗-批量导入',
      trackName: $route.path
    }"
    @submitSuccess="handleImportSubmit"
    @cancel="handleImportCancel"
    @clearAll="handleImportClearAll"
  >
    <template #titleBtnLeft>
      <el-row :gutter="24">
        <el-col :span="5">
          <el-upload
            ref="uploadOne"
            :limit="1"
            :show-file-list="false"
            name="file"
            action=""
            multiple
            :http-request="handleUploadHttpRequestOne"
            :on-success="handleUploadOneSuccess"
          >
            <nh-button type="primary" :loading="loading.handleUploadHttpRequestOne">
              一维导入
            </nh-button>
          </el-upload>
        </el-col>
        <el-col :span="5">
          <el-upload
            ref="uploadTwo"
            :limit="1"
            :show-file-list="false"
            name="file"
            action=""
            multiple
            :http-request="handleUploadHttpRequestTwo"
            :on-success="handleUploadTwoSuccess"
          >
            <nh-button type="primary" :loading="loading.handleUploadHttpRequestTwo">
              二维导入
            </nh-button>
          </el-upload>
        </el-col>
        <el-col :span="6">
          <nh-button type="primary" @click="handleOneTemplate">
            一维模板下载
          </nh-button>
        </el-col>
        <el-col :span="6">
          <nh-button type="primary" @click="handleTwoTemplate">
            二维模板下载
          </nh-button>
        </el-col>
      </el-row>
    </template>
    <template #describe>
      <el-divider />
      <div class="warm-tips">
        <p>1.整单位和零单位相同时，则只填写整单位数量，零单位数量填0</p>
        <p>
          2.一个excel中可以录入多个订单，订单类别、货主、出库时间、门店、货主订单号、是否仓配一体上述维度一致时，则是一个订单，否则是多个订单。
        </p>
      </div>
      <el-divider />
    </template>
    <template #footerSlot>
      <nh-button v-if="errMsgList.length > 0" @click="handleExportError">
        导出异常
      </nh-button>
    </template>
  </nh-data-import>
  <nh-data-import
    ref="ladingOrderImport"
    :err-msg-list="ladingOrderErrMsgList"
    :is-upload="ladingOrderIsUpload"
    :track="{
      name_zh: '出库管理/出库单管理/弹窗-导入提货信息',
      trackName: $route.path
    }"
    :show-content="radio === UPLOAD"
    @upload="ladingOrderImport"
    @downLoadTemplate="handleDownLadingOrderTemplate"
    @submitSuccess="handleLadingOrderSubmitSuccess"
    @cancel="handleLadingOrderCancel"
    @clearAll="handleLadingOrderClearAll"
  >
    <template #title>
      更新排线计划
    </template>
    <template #bodyTitle>
      <el-form>
        <el-form-item label="更新方式">
          <el-radio-group v-model="radio" @change="handleUploadCloseAll">
            <el-radio
              v-for="item in UPLOAD_TYPE"
              :key="item.vaule"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </template>
    <template v-if="radio !== UPLOAD" #titleBtnLeft>
      <div />
    </template>
  </nh-data-import>
  <detail ref="detail" />
  <intercept ref="intercept" @handleIntercept="loadDeliverOrderPage" />
  <Add ref="add" @handleSave="loadDeliverOrderPage" />
  <stockCheckDialog
    ref="stockCheckDialog"
    :data="stock.errorMsgs"
    :submiting="loading.handleSaveStock"
    @onSubmit="handleSaveStock"
    @onExport="handleStockExport"
  />
  <el-dialog
    v-model="dialogTableVisible"
    title="提示"
  >
    <div v-if="statusFlag===1">
      以下出库单已是加急状态,导致无法点击订单加急,请重新选择
    </div>
    <div v-else>
      以下出库单已是正常状态,导致无法点击取消加急,请重新选择
    </div>
    <ul
      v-for="(i,index) in gridData"
      :key="index"
      class="noticeLIST"
    >
      <li>
        <span>{{ i.deliverOrderNo }}</span>
        <span>
          <span class="tag" :style="{background:i.urgent?'#CC0707':'#07CC6D'}" />
          <span>{{ i.urgent?'加急':'正常' }}</span>
        </span>
      </li>
    </ul>
  </el-dialog>
  <OrderCreateDrawer ref="orderCreate" @onSuccess="handleQueryFrom" />
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import OutWarehouse from '@/constant/pageNames/outWarehouse';
import { ONE_DIMENSIONAL_IMPORT, TWO_DIMENSIONAL_IMPORT } from '@/constant/import';

import {
  // WAVE_COMPOSE_TYPE_ENUM,
  DELIVER_STSTUS_ENUM_VALUE, SOURCE_ENUM,
  UPLOAD_TYPE, UPLOAD,
} from './constant';
import {
  deliverOrderPage,
  deliverOrderCancel,
  // waveOrderCreate,
  deliverOrderExport,
  downLoadTemplate,
  importTwoDimensionalCheck,
  importOneDimensionalCheck,
  batchImport,
  deliverOrderLadingImportCheck,
  deliverOrderLadingImport,
  checkOneDimensionalImportDeliverOrderStock,
  checkErrorExport,
  stockErrorExport,
  getCityAreaTree,
  deliverOrderFillLine,
  deliverOrderUrgent,
} from './api';
import mixin from './mixin';
import AddWaveDialog from './waveCreate/index.vue';
import { TABLECOLUMN, serchFields } from './fileds';
import Detail from './detail/index.vue';
import intercept from './intercept/index.vue';
import Add from './add.vue';
import stockCheckDialog from './subpages/stockCheckDialog/index.vue';
import OrderCreateDrawer from './OrderCreateDrawer/OrderCreateDrawer.vue';

export default {
  name: OutWarehouse.DELIVER_ORDER,
  components: {
    AddWaveDialog,
    Detail,
    Add,
    stockCheckDialog,
    intercept,
    OrderCreateDrawer,
  },
  mixins: [loadingMixin, mixin],
  provide: {
    nameZh: '出库单管理',
  },
  data() {
    return {
      stock: {
        errorMsgs: [],
        data: [],
      },
      TABLECOLUMN,
      serchFields,
      tableData: [],
      formData: {
        shipperId: null,
      },
      loading: {
        handleSaveStock: false,
        loadDeliverOrderPage: false,
        handleDeliverOrderClose: false,
        orderUrgent: false,
      },
      selectIds: [],
      orderUrgent: [],
      allLadingOrder: [],
      errMsgList: [],
      isUpload: false,
      dialogTableVisible: false,
      statusFlag: 0,
      uuid: null,
      ladingOrderErrMsgList: [],
      allLadingPick: [],
      ladingOrderIsUpload: false,
      importType: null,
      cityList: [],
      cityTreelevel: 1,
      UPLOAD_TYPE,
      UPLOAD,
      radio: UPLOAD,
      gridData: [],

    };
  },
  // computed: {
  //   tableCol() {
  //     const DISTRIBUTION = this.$dictionaryFiledMap.DISTRIBUTE_TYPE || [];
  //     return TABLECOLUMN(DISTRIBUTION);
  //   },
  // },
  created() {
    this.getCityTree();
    this.loadDeliverOrderPage();
  },
  methods: {
    /**
     * 将选中的数据行 id 存于 selectIds 中
     */
    updateSelectDeliverOrderIds(selectIds) {
      this.selectIds = selectIds;
    },

    resetQuery() {
      this.formData = {
        shipperId: null,
      };
    },
    handleQueryFrom(data) {
      const formData = {
        ...data,
        shipperId: this.formData.shipperId || null,
      };
      Object.keys(formData).forEach((item) => {
        formData[item] = formData[item] === '' ? null : formData[item];
      });
      this.pagination.page = 1;
      // 调用表单重置 时间控件会跳到 1970 年，增加default 解决问题后 导致 重置 会携带默认值，所以在这里判定，只有一个值的时候即为重置
      const createTime = !(formData.createTime && formData.createTime.length)
        || (formData.createTime
          && formData.createTime instanceof Array
          && formData.createTime.length < 2);
      const bookingTime = !(formData.bookingTime && formData.bookingTime.length)
        || (formData.bookingTime
          && formData.bookingTime instanceof Array
          && formData.bookingTime.length < 2);
      if (createTime) {
        formData.createTime = [];
      }
      if (bookingTime) {
        formData.bookingTime = [];
      }
      const stationCity = formData?.city?.reduce((prev, curr) => {
        const [prov, city] = curr || [];
        if (prov || city) {
          return [...prev, city || prov];
        }
        return prev;
      }, []) || [];
      this.formData = {
        ...formData,
        stationCity: stationCity.length > 0 ? stationCity : undefined,
        distributeTypeCode: formData.distributeTypeCode?.length > 0
          ? formData.distributeTypeCode : undefined,
        beforeCreateTime:
          formData.createTime
          && formData.createTime[0]
          && moment(formData.createTime[0]).startOf('day'),
        afterCreateTime:
          formData.createTime
          && formData.createTime[1]
          && moment(formData.createTime[1]).endOf('day'),
        beforeBookingTime:
          formData.bookingTime
          && formData.bookingTime[0]
          && moment(formData.bookingTime[0]).startOf('day'),
        afterBookingTime:
          formData.bookingTime
          && formData.bookingTime[1]
          && moment(formData.bookingTime[1]).endOf('day'),
      };
      this.loadDeliverOrderPage();
    },
    headleAddOutboundOrder() {
      this.$refs.add.init();
    },
    headleEditDeliverOrder(rowData) {
      this.$refs.add.init(rowData.id);
    },
    handleShowBatchImport() {
      this.$refs.batchImport.showDialog();
    },
    handleLadingOrderImport() {
      this.$refs.ladingOrderImport.showDialog();
    },
    async handleDeliverOrderClose() {
      const ids = this.selectIds;
      if (ids.length <= 0) {
        await this.$message({
          message: '必须选择一条数据后才能进行取消',
          type: 'warning',
        });
      } else {
        await this.$confirm('是否取消该出库单？', '订单取消', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await this.deliverOrderCancel(ids);
      }
    },
    handleAddWave() {
      this.$refs.addWaveDialog.showWaveCreateDialog();
    },
    handleBatchAddWave() {
      this.waveOrderCreate();
    },
    handleSelectionChange(selection) {
      this.orderUrgent = selection;
      this.updateSelectDeliverOrderIds(selection.map((item) => item.id));
    },
    handleBathImportSumit() {
      this.loadDeliverOrderPage();
    },
    handleAddWaveSuccess() {
      this.loadDeliverOrderPage();
    },
    handleSuccessImport() {
      this.pagination.page = 1;
      this.loadDeliverOrderPage();
    },
    async handleExport() {
      const data = {
        ids: this.selectIds,
        ...this.formData,
      };
      await deliverOrderExport(data, { fileName: '出库单', extension: 'xlsx' });
      this.clearTableSelect();
    },
    showEditButton(rowData) {
      return rowData.source === SOURCE_ENUM.WMS
      && (rowData.deliverStatus === DELIVER_STSTUS_ENUM_VALUE.PRE_SEND_CAR
       || rowData.deliverStatus === DELIVER_STSTUS_ENUM_VALUE.PRE_AUDIT
        || rowData.deliverStatus === DELIVER_STSTUS_ENUM_VALUE.PRE_WAVE);
    },
    clearTableSelect() {
      this.selectIds = [];
    },
    async loadDeliverOrderPage() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await deliverOrderPage(pagination, this.formData);
      this.tableData = result.records;
      this.pagination.total = result.total;
    },
    async deliverOrderCancel(ids) {
      await deliverOrderCancel({ ids });
      this.$message({
        message: '取消成功!',
        type: 'success',
      });
      this.clearTableSelect();
      await this.loadDeliverOrderPage();
    },
    async waveOrderCreate() {
      if (this.selectIds.length <= 0) {
        await this.$message({
          message: '必须选择一条数据后才能进行创建波次',
          type: 'warning',
        });
      } else {
        this.$refs.orderCreate.init(this.selectIds);
      }
    },
    async handlePrintDelivery() {
      if (this.selectIds.length > 0) {
        const routeUrl = this.$router.resolve({
          path: '/print/delivery-list',
          query: { ids: this.selectIds },
        });
        window.open(routeUrl.href, '_blank');
      }
    },

    async handleUploadHttpRequestOne(file) {
      this.uuid = null;
      this.setImportType(ONE_DIMENSIONAL_IMPORT);
      const fd = new FormData();
      fd.append('file', file.file);
      const { data, errorMsg = [], uuid } = await importOneDimensionalCheck(fd);
      this.uuid = uuid;
      this.isUpload = true;
      this.errMsgList = [...this.errMsgList, ...errorMsg.map((item) => ({ errorMsg: item }))];
      this.allLadingOrder = [...this.allLadingOrder, ...data];
    },
    handleUploadOneSuccess() {
      this.$refs.uploadOne.clearFiles();
    },
    handleUploadTwoSuccess() {
      this.$refs.uploadTwo.clearFiles();
    },
    async handleUploadHttpRequestTwo(file) {
      this.setImportType(TWO_DIMENSIONAL_IMPORT);
      const fd = new FormData();
      fd.append('file', file.file);
      const { data, errorMsg = [] } = await importTwoDimensionalCheck(fd);
      this.isUpload = true;
      this.errMsgList = [...this.errMsgList, ...errorMsg.map((item) => ({ errorMsg: item }))];
      this.allLadingOrder = [...this.allLadingOrder, ...data];
    },
    handleOneTemplate() {
      this.downloadTemplate('deliver_order_one_dimensional_template_id', '出库单一维导入模板');
    },
    handleTwoTemplate() {
      this.downloadTemplate('deliver_order_two_dimensional_template_id', '出库单二维导入模板');
    },
    handleImportClearAll() {
      this.allLadingOrder = [];
      this.isUpload = false;
      this.errMsgList = [];
    },
    async handleImportSubmit() {
      // 如果有错误信息，提示错误
      if (this.errMsgList?.length > 0) {
        this.$message({
          message: '请根据错误信息修改上传后再保存',
          type: 'warning',
        });
        return;
      }

      if (this.importType === ONE_DIMENSIONAL_IMPORT) {
        // 如果是一维导入，校验出库单库存
        await this.checkStock();
        return;
      }

      const params = { data: this.allLadingOrder };

      this.saveData(params);
    },
    async handleSaveStock() {
      const params = {
        data: this.stock.data,
        useAvailableAmount: true,
      };
      await this.saveData(params);
      this.closeStockTip();
    },
    async saveData(params) {
      await batchImport(params);

      this.$message({
        message: '保存成功',
        type: 'success',
      });
      this.handleImportCancel();
      this.loadDeliverOrderPage();
    },
    async checkStock() {
      const params = {
        uuid: this.uuid,
        data: this.allLadingOrder,
        useAvailableAmount: false,
      };
      const res = await checkOneDimensionalImportDeliverOrderStock(params);
      const { errorMsg, data } = res;

      this.stock.errorMsgs = errorMsg;
      this.stock.data = data;

      if (errorMsg?.length > 0) {
        this.showStockTip();
      } else {
        this.saveData({
          data,
          useAvailableAmount: false,
        });
      }
    },
    showStockTip() {
      this.$refs.stockCheckDialog.open();
    },
    closeStockTip() {
      this.$refs.stockCheckDialog.close();
    },
    handleImportCancel() {
      this.handleImportClearAll();
      this.$refs.batchImport.handleClose();
    },
    async downloadTemplate(templateCode, fileName) {
      await downLoadTemplate({ templateId: templateCode }, fileName);
    },

    async ladingOrderImport(fd) {
      const { data, errorMsg = [] } = await deliverOrderLadingImportCheck(fd);
      this.ladingOrderErrMsgList = [
        ...this.ladingOrderErrMsgList,
        ...errorMsg.map((item) => ({ errorMsg: item })),
      ];
      this.ladingOrderIsUpload = true;
      this.allLadingPick = [...this.allLadingPick, ...data];
    },
    async handleDownLadingOrderTemplate() {
      await downLoadTemplate({ templateId: 'lading_order_template_id' }, '提货信息导入模板');
    },
    async handleLadingOrderSubmitSuccess() {
      if (this.ladingOrderErrMsgList && this.ladingOrderErrMsgList.length <= 0) {
        if (this.radio !== this.UPLOAD) {
          await deliverOrderFillLine({ ids: this.selectIds });
        } else {
          await deliverOrderLadingImport({ ladings: this.allLadingPick });
        }
        this.$message({
          message: '保存成功',
          type: 'success',
        });
        this.handleLadingOrderCancel();
        this.pagination.page = 1;
        this.loadDeliverOrderPage();
      } else {
        this.$message({
          message: '请根据错误信息修改后再上传',
          type: 'warning',
        });
      }
    },
    handleLadingOrderCancel() {
      this.handleLadingOrderClearAll();
      this.$refs.ladingOrderImport.handleClose();
    },
    handleLadingOrderClearAll() {
      this.radio = UPLOAD;
      this.handleUploadCloseAll();
    },
    handleUploadCloseAll() {
      this.allLadingPick = [];
      this.ladingOrderIsUpload = false;
      this.ladingOrderErrMsgList = [];
      this.stock = {
        errorMsgs: [],
        data: null,
      };
    },
    async  handleOrderUrgent(num) {
      this.statusFlag = num;
      if (this.orderUrgent.length <= 0) {
        this.$message.error('请至少勾选一行数据!');
      } else {
        const flag = this.orderUrgent.every((i) => i.deliverStatus === 'PRE_SEND_CAR' || i.deliverStatus === 'PRE_AUDIT' || i.deliverStatus === 'PRE_WAVE' || i.deliverStatus === 'IN_WAVE' || (i.deliverStatus === 'IN_PRODUCTION' && i.waveOrderStatus !== 'PICK_COMPLETED'));// 检验出库单状态是否符合条件
        if (flag) {
          const res = await deliverOrderUrgent(
            { urgent: num === 1, ids: this.orderUrgent.map((i) => i.id) },
          );
          if (!res) {
            this.$message.success('操作成功!');
            this.loadDeliverOrderPage();
          } else {
            this.dialogTableVisible = true;
            this.gridData = res[num === 1].map((i) => (
              { deliverOrderNo: i, urgent: num === 1 }
            ));
          }
        } else {
          this.$message.error('只能对出库单状态是待派车,待审核,待组波,波次中或者出库状态是生产中并且关联波次状态不是拣货完成的单子进行操作,请重新选择!');
        }
      }
    },
    headleOndetail(id) {
      this.$refs.detail.init(id);
    },

    headleintercept(id) {
      this.$refs.intercept.init(id);
    },
    setImportType(type) {
      this.importType = type;
    },
    // 导出基础异常
    async handleExportError() {
      checkErrorExport(
        {
          uuid: this.uuid,
        },
        { fileName: '基本信息异常', extension: 'xlsx' },
      );
    },
    // 库存异常
    async handleStockExport() {
      stockErrorExport(
        {
          uuid: this.uuid,
        },
        { fileName: '库存异常信息', extension: 'xlsx' },
      );
    },
    async getCityTree() {
      const res = await getCityAreaTree({
        filterBlackList: true,
      });
      const city = JSON.parse(JSON.stringify(res));
      this.cityList = this.filterData(city, 0);
    },
    filterData(data, level) {
      data.forEach((e) => {
        e.label = e.areaName;
        // e.value = e.areaCode;
        e.value = e.areaName;
        if (level === this.cityTreelevel) {
          e.children = null;
        }
        if (level <= this.cityTreelevel && e.children && e.children.length > 0) {
          this.filterData(e.children, level + 1);
        } else {
          e.children = null;
        }
      });
      return data;
    },
    // getCityparmas() {
    //   const cityCodeMaps = {};
    //   const provincesCodeMaps = {};
    //   const result = [];
    //   this.formData.city.forEach((item) => {
    //     const citykey = item.join('');
    //     cityCodeMaps[citykey] = citykey;
    //     const provincesCode = item[0];
    //     provincesCodeMaps[provincesCode] = provincesCode;
    //   });

    //   this.cityList.forEach((provinceItem) => {
    //     if (
    //       provinceItem.children
    //       && provinceItem.children.length > 0
    //       && provincesCodeMaps[provinceItem.areaCode]
    //     ) {
    //       provinceItem.children.forEach((cityitem) => {
    //         const key = provinceItem.areaCode + cityitem.areaCode;
    //         if (cityCodeMaps[key]) {
    //           result.push(provinceItem.areaName + cityitem.areaName);
    //         }
    //       });
    //     }
    //   });

    //   return result;
    // },
    handleChangeCity() {
      // const cityList = this.formData.city.map((item) => item.join(''));
      // console.log(cityList);
    },
  },
};
</script>
<style scoped lang="scss">
.el-radio {
  display: inline-flex;
}

.action {
  padding: 15px 0;
}

.full-content {
  :deep(.cursor) {
    cursor: pointer;
  }
}

.cursor-color {
  color: aquamarine;
}

.warm-tips > p {
  padding: 4px 0;
}

.tableurgent {
  margin-top: 20px;
  width: 270px;
}

.tag {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-left: 20px;
  margin-right: 5px;
}

.noticeLIST {
  width: 270px;
  margin-top: 10px;
  li {
    display: flex;
    justify-content: space-between;
    height: 30px;
    align-items: center;

  }
}

</style>
