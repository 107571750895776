<template>
  <el-dialog
    v-model="visible"
    title="指定库存"
    width="50%"
    :before-close="handleClose"
  >
    <el-form
      ref="ruleForm"
      :model="searchForm"
      label-width="120px"
    >
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="货品编码">
            <p>{{ searchForm.goodsCode }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="货品名称">
            <p>{{ searchForm.goodsName }}</p>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="指定库存">
            <el-radio-group
              v-model="searchForm.radioType"
              @change="handleRadioChange"
            >
              <el-radio
                v-for="item in RADIO_TYPE"
                :key="item.value"
                :label="item.value"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <template v-if="RADIO_TYPE[0].value === searchForm.radioType">
          <el-col :span="5" :offset="1">
            <el-form-item label-width="0" prop="attributeName">
              <el-select v-model="searchForm.attributeName" placeholder="请选择类型">
                <el-option
                  v-for="item in batchAttributeCodes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label-width="0" prop="attributeValue">
              <el-input v-model="searchForm.attributeValue" />
            </el-form-item>
          </el-col>
        </template>
        <el-col v-else :span="10">
          <el-form-item label="库位号" prop="storehouseCode">
            <el-input v-model="searchForm.storehouseCode" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <nh-button type="primary" @click="handleSearch">
            查询
          </nh-button>
          <nh-button @click="handleReset">
            重置
          </nh-button>
        </el-col>
      </el-row>
    </el-form>
    <div class="flex_column full-content">
      <PlTable
        v-if="RADIO_TYPE[0].value === searchForm.radioType"
        :loading="loading.loadBatchAttributePage"
        :data="bathList"
        :columns="TABLECOLUMN(batchAttributeCodes)"
        height="260px"
      >
        <template #radio="scope">
          <div class="radio-box">
            <el-radio
              v-model="tableRadio"
              :disabled="scope.row.amount <= 0"
              :label="scope.row.batchNo"
              @change="handleSelectBatchAttribute(scope.row)"
            />
          </div>
        </template>
      </PlTable>
      <PlTable
        v-else
        :loading="loading.getSumGoodsAmount"
        :data="sumGoodsAmount"
        :columns="TABLECOLUMN_STOREHOUSE"
        height="260px"
      >
        <template #radio="scope">
          <div class="radio-box">
            <el-radio
              v-model="tableRadio"
              :label="scope.row.locationId"
              @change="handleSelectBatchAttribute(scope.row)"
            />
          </div>
        </template>
      </PlTable>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <nh-button type="primary" @click="headleConfirm">
          确定
        </nh-button>
        <nh-button @click="handleClose">
          取消
        </nh-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import loadingMixin from '@thales/loading';
import RADIO_TYPE from './constant';

import { batchAttribute, batchFieldList, querySumGoodsAmount } from '../api';
import { TABLECOLUMN, serchFields, TABLECOLUMN_STOREHOUSE } from './fileds';

export default {
  name: 'AddBatch',
  components: {},
  filters: {},
  mixins: [loadingMixin],
  props: {},
  emits: ['selectBatchAttr'],

  data() {
    return {
      serchFields,
      TABLECOLUMN,
      TABLECOLUMN_STOREHOUSE,
      bathList: [],
      tableRadio: '1',
      visible: false,
      RADIO_TYPE,
      searchForm: {
        goodsId: null,
        goodsName: null,
        id: null,
        radioType: RADIO_TYPE[0].value,
        attributeName: null,
        attributeValue: null,
        location: null,
      },
      selctedBatchAttrbute: null,
      batchAttributeCodes: [],
      sumGoodsAmount: [],
      loading: {
        loadBatchAttributePage: false,
        getSumGoodsAmount: false,
      },
    };
  },
  methods: {
    async init(rowData) {
      this.searchForm = {
        ...rowData,
        storehouseCode: null,
        radioType: rowData.storehouseId ? RADIO_TYPE[1].value : RADIO_TYPE[0].value,
      };
      this.tableRadio = rowData.batchNo || rowData.storehouseId;
      const batchAttributeId = rowData.qualityControlExt?.batchAttributeId;
      if (batchAttributeId) {
        await this.loadBatchFieldList(batchAttributeId);
      }
      if (this.searchForm.radioType === RADIO_TYPE[0].value) {
        await this.loadBatchAttributePage(rowData.id);
      } else {
        await this.getSumGoodsAmount();
      }
      this.getSelctedBatchAttrbute();
      this.visible = true;
    },
    async handleRadioChange(val) {
      if (val === RADIO_TYPE[0].value) {
        await this.loadBatchAttributePage();
      } else {
        await this.getSumGoodsAmount();
      }
      this.getSelctedBatchAttrbute();
    },
    // 根据库存类型和选中标记，获取选择对象
    getSelctedBatchAttrbute() {
      let selctedBatchAttrbute = null;
      if (this.searchForm.radioType === RADIO_TYPE[0].value) {
        for (let i = 0, l = this.bathList.length; i < l; i += 1) {
          if (this.bathList[i].batchNo === this.tableRadio) {
            selctedBatchAttrbute = this.bathList[i];
            break;
          }
        }
      } else {
        for (let i = 0, l = this.sumGoodsAmount.length; i < l; i += 1) {
          if (this.sumGoodsAmount[i].locationId === this.tableRadio) {
            selctedBatchAttrbute = this.sumGoodsAmount[i];
            break;
          }
        }
      }
      this.selctedBatchAttrbute = selctedBatchAttrbute;
    },
    handleClose() {
      this.visible = false;
    },
    headleConfirm() {
      if (this.selctedBatchAttrbute) {
        this.$emit('selectBatchAttr', this.selctedBatchAttrbute);
        this.visible = false;
      } else {
        this.$message({
          message: `请选择一项${this.searchForm.radioType === RADIO_TYPE[0].value ? '批属性' : '库位'}后再确定`,
          type: 'warning',
        });
      }
    },
    handleSelectBatchAttribute(rowData) {
      this.selctedBatchAttrbute = rowData;
    },
    handleReset() {
      this.$refs.ruleForm.resetFields();
    },
    handleSearch() {
      if (this.searchForm.radioType === RADIO_TYPE[0].value) {
        this.loadBatchAttributePage();
      } else {
        this.getSumGoodsAmount();
      }
    },
    async loadBatchAttributePage() {
      this.bathList = await batchAttribute({
        goodsQuality: this.searchForm.quality,
        goodsId: this.searchForm.goodsId,
        attributeName: this.searchForm.attributeName,
        attributeValue: this.searchForm.attributeValue,
      }) || [];
    },
    async getSumGoodsAmount() {
      this.sumGoodsAmount = await querySumGoodsAmount({
        goodsId: this.searchForm.goodsId,
        storehouseCode: this.searchForm.storehouseCode,
        goodsQuality: this.searchForm.quality,
      }) || [];
    },
    async loadBatchFieldList(id) {
      let result = await batchFieldList({ id });
      result = result || [];
      this.batchAttributeCodes = result.batchAttribute.map((item) => ({
        ...item,
        label: item.fieldName,
        value: item.fieldCode,
      }));
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}

.radio-box {
  width: 15px;
  overflow: hidden;
}
</style>
