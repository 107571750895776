<template>
  <div>
    <PlTable
      :show-overflow-tooltip="true"
      :loading="loading.getTableData"
      :data="goods"
      border
      :columns="TABLECOLUMN(GOODS_QUALITY)"
      @selection-change="handleSelectionChange"
    />
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import tableSummary from '@/utils/tableSummary';

import TABLECOLUMN from './fileds';
import summaryConfig from './config';

export default {
  name: 'InterceptTable',
  mixins: [loadingMixin],
  props: {
    goods: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      TABLECOLUMN,
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  methods: {
    handleGetSummaries({ columns, data }) {
      const config = summaryConfig;
      const sums = [];
      columns.forEach((column, index) => {
        sums[index] = tableSummary({ column, data, config });
      });
      return sums;
    },
  },
};
</script>
<style scoped>
.width-100 {
  width: 100%;
}
</style>
