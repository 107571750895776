import { FormType } from '@/constant/form';
import { TEMPERATURE_LAYER_CODE_ENUM, TEMPERATURE_LAYER_CODE } from '../constant';

export const TABLECOLUMN = [{
  prop: 'selection',
  type: 'selection',
  width: 50,
  align: 'center',
}, {
  label: '货主货品编号',
  prop: 'shipperGoodsCode',
  width: 180,
}, {
  label: '货品名称',
  prop: 'goodsName',
  width: 180,
}, {
  label: '温层',
  prop: 'temperatureLayerCode',
  width: 180,
  formatter: (row) => TEMPERATURE_LAYER_CODE_ENUM[row.temperatureLayerCode],
}, {
  label: '规格',
  prop: 'goodsSpecifications',
  width: 180,
}, {
  label: '默认单位',
  prop: 'defaultUnit',
  width: 180,
}];
export const serchFields = [
  {
    label: '货品编码',
    prop: 'goodsCode',
    component: FormType.INPUT,
  }, {
    label: '货品名称',
    prop: 'goodsName',
    component: FormType.INPUT,
  }, {
    label: '温层',
    prop: 'temperatureLayerCode',
    component: FormType.SELECT,
    options: TEMPERATURE_LAYER_CODE,
  },
];
