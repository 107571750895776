const tableColumns = [
  {
    label: '序号',
    type: 'index',
    width: 50,
  },
  {
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    minWidth: '120px',
  },
  {
    label: '货品名称',
    prop: 'goodsName',
  },
  {
    label: '货主名称',
    prop: 'shipperName',
  },
  {
    label: '站点名称',
    prop: 'stationName',
  },
  {
    label: '指定批次',
    prop: 'batchNo',
    formatter: (row) => (row.batchNo || '-'),
  },
  {
    label: '指定库位',
    prop: 'storehouseCode',
    formatter: (row) => (row.storehouseCode || '-'),
  },
  {
    label: '预约数量',
    prop: 'originalBookingAmount',
  },
  {
    label: '可用库存',
    prop: 'availableAmount',
    formatter: (row) => (row.availableAmount || '-'),
  },
];

export default tableColumns;
