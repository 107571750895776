// 一维导入
export const ONE_DIMENSIONAL_IMPORT = 'oneDimensionalImport';
// 二维导入
export const TWO_DIMENSIONAL_IMPORT = 'twoDimensionalImport';

// 导入类型
export const ImportType = {
  ONE_DIMENSIONAL_IMPORT,
  TWO_DIMENSIONAL_IMPORT,
};
