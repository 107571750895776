import { TEMPERATURE_LAYER_CODE_NEW_ENUM } from '../constant';

export const suppUnits = 'bookingAuxiliaryAmount'; // 辅助单位数量
export const QUALITY = 'quality';
export default [{
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  width: 180,
}, {
  label: '货品名称',
  prop: 'goodsName',
  width: 160,
}, {
  label: '温层',
  prop: 'temperatureLayer',
  ENUM: TEMPERATURE_LAYER_CODE_NEW_ENUM,
}, {
  label: '规格',
  prop: 'spec',
}, {
  label: '默认单位',
  prop: 'defaultUnit',
}, {
  label: '预约数量(整单位)',
  prop: 'bookingNumBig',
  width: 150,
}, {
  label: '预约数量(零单位)',
  prop: 'bookingNumSmall',
  width: 150,
}, {
  label: '预约辅助单位数量',
  prop: suppUnits,
  width: 150,
}, {
  label: '货品质量',
  prop: 'quality',
  width: 150,
}, {
  label: '指定批次',
  prop: 'batchNo',
}, {
  label: '指定库位',
  prop: 'storehouseCode',
},
];
