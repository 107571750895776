<template>
  <nh-drawer
    v-model="drawerVisible"
    v-loading="loading.loadDeliverOrderDetail"
    :before-close="handleClose"
    destroy-on-close
    title="订单拦截"
    size="1111px"
    :track="{
      name_zh: `出库管理/${nameZh}/抽屉-详情`,
      trackName: $route.path
    }"
  >
    <el-alert
      title="注意：拦截后订单中所有库存都将回到该指定库位，订单将回到待组波状态"
      type="warning"
      show-icon
    />
    <div class="footerLeft">
      <div>回库库位</div>
      <el-select
        v-model="storehouseId"
        filterable
        :remote="true"
        reserve-keyword
        placeholder="请选择回库库位"
        :remote-method="remoteMethod"
        loading-text="加载中……"
        class="footerSelect"
        @change="handleSelect"
      >
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <nh-dialog-form
      class="owner-detail"
      label-position="right"
    >
      <nh-dialog-form-item
        v-for="(item, index) in DetailFromInfo(StoreData)"
        :key="index"
        :span="8"
        :label="item.label"
      >
        <span v-if="item.prop === 'distributeTypeCode'">
          {{ DISTRIBUTION.find((dis => dis.value === StoreData.distributeTypeCode))?.label }}
        </span>
        <span v-else-if="item.timeFormat">
          {{ item.timeFormat(StoreData[item.prop]) }}
        </span>
        <span v-else-if="item.ENUM">{{ item.ENUM[StoreData[item.prop]] }}</span>
        <span v-else>{{ StoreData[item.prop] }}</span>
      </nh-dialog-form-item>
      <nh-dialog-form-item
        :span="24"
        label="附件："
      >
        <el-image
          v-for="(item) in files.images"
          :key="item.id"
          style="width: 100px; height: 100px;"
          class="preview-image"
          :src="item.filePath"
          :preview-src-list="previewImageSrcList"
          :initial-index="1"
          fit="cover"
        />
        <div v-if="files.otherFiles.length > 0" class="file-show-box">
          <div
            v-for="item in files.otherFiles"
            :key="item.id"
            class="file-rows"
          >
            <div class="file-info">
              <div class="file-name">
                {{ getFileName(item.filePath) }}
              </div>
            </div>
            <div class="file-opera">
              <nh-button
                type="text"
                @click="handleDownloadFile(item)"
              >
                下载
              </nh-button>
            </div>
          </div>
        </div>
      </nh-dialog-form-item>
    </nh-dialog-form>
    <div class="block">
      <div class="lineBar">
        <span class="line" />
        <span>出库单明细</span>
      </div>
      <DetailTable :goods="StoreData.goods" />
    </div>
    <template #footer>
      <div class="footer">
        <nh-button
          @click="handleClose"
        >
          取消
        </nh-button>
        <nh-button
          type="primary"
          @click="handleIntercept"
        >
          确认拦截
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import DetailTable from '../interceptTable/detailTable.vue';
import {
  deliverOrderDetail, defaultLocation, locationQuery, confirmIntercept,
} from '../api';
import DetailFromInfo from './detailForm';
import mixin from '../mixin';
import { getFileNameByFileUrl, downloadFile } from '../utils';

export default {
  name: 'InterceptDetail',
  components: {
    DetailTable,
  },
  filters: {
    filterLabel(val, arr) {
      const filertArr = arr && arr.find((item) => item.value === val);
      return filertArr && filertArr.label;
    },
  },
  mixins: [mixin, loadingMixin],
  inject: ['nameZh'],
  emits: ['handleIntercept'],
  data() {
    return {
      drawerVisible: false,
      DetailFromInfo,
      loading: {
        loadDeliverOrderDetail: false,
      },
      StoreData: {
      },
      receiving: [{
        temp: '',
        time: '10:00-18:00',
      }],
      delivery: [{
        temp: '',
        time: '10:00-18:00',
      }],
      storehouseCode: '',
      options: [],
      Id: '',
      storehouseId: '',
    };
  },
  computed: {
    files() {
      const images = [];
      const otherFiles = [];

      const IMAGE_SUFFIXS = ['jfif', 'png', 'bmp', 'jpg', 'gif'];

      this.StoreData?.fileAttachments?.forEach((element) => {
        const isImage = IMAGE_SUFFIXS.some((suffix) => element.filePath.endsWith(suffix));

        if (isImage) {
          images.push(element);
        } else {
          otherFiles.push(element);
        }
      });

      return {
        images,
        otherFiles,
      };
    },
    previewImageSrcList() {
      return this.files.images.map(({ filePath }) => filePath);
    },
  },
  methods: {
    init(id) {
      this.loadDeliverOrderDetail(id);
      this.Id = id;
      this.drawerVisible = true;
      this.remoteMethod('', 1);
    },
    async loadDeliverOrderDetail(id) {
      this.StoreData = await deliverOrderDetail({ id });
      const data = await defaultLocation({ id });
      this.storehouseId = data.id || '';
      this.storehouseCode = data.storageLocationCode || '';
    },
    handleClose() {
      this.drawerVisible = false;
    },
    async  handleIntercept() {
      if (!this.storehouseId) {
        this.$message.error('请选择回库库位!');
      } else {
        await confirmIntercept({
          id: this.Id,
          storehouseCode:
          this.storehouseCode,
          storehouseId: this.storehouseId,
        });
        this.storehouseId = '';
        this.storehouseCode = '';
        this.$emit('handleIntercept');
        this.$message.success('拦截成功!');
        this.drawerVisible = false;
      }
    },
    getFileName(value) {
      return this.fileNameRemoveTime(getFileNameByFileUrl(value, true));
    },
    fileNameRemoveTime(fileName) {
      const fileNames = `${fileName}` ? fileName.split('_') : [];
      // 上传时是使用下划线_ 区分时间戳和文件名的，展示时 去掉时间戳
      return fileNames.filter((item, index) => index > 0).join('_');
    },
    handleDownloadFile(fileItem) {
      downloadFile('/warehouse_management_system/file/download', { path: fileItem.filePath });
    },
    remoteMethod(query, num) {
      this.options = [];
      if (query !== '' || num) {
        const params = {
          storageLocationCode: query,
          page: 1,
          pageSize: 10,
        };
        locationQuery(params)
          .then((resp) => {
            if (resp.records.length <= 0) {
              this.$message.error('暂无库位');
              return;
            }
            resp.records.forEach((item) => {
              this.options.push({
                label: item.storageLocationCode,
                value: item.id,
              });
            });
          })
          .catch(({ resp }) => {
            resp.records.forEach((item) => {
              this.options.push({
                label: item.storageLocationCode,
                value: item.id,
              });
            });
          });
      } else {
        this.options = [];
      }
    },
    handleSelect(val) {
      this.storehouseCode = this.options.filter((i) => i.value === val)[0].label;
    },
  },
};
</script>
<style lang="scss" scoped>
.footerLeft {
  height: 60px;
  display: flex;
  align-items: center;
  // margin-top: 20px;

  border-bottom: 1px solid #dcdee0;

  div {
    margin-right: 20px;
  }
}

.file-show-box {
  padding: 12px 24px;
  background-color: #f6f8fa;
  border-radius: 4px;
  width: 86%;

  .file-rows {
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .file-info {
      & > div {
        height: 24px;
      }

      .file-name {
        color: #606266;
        font-size: 14px;
      }

      .file-upload-time {
        font-size: 14px;
        color: #909399;
      }
    }

    .file-opera {
      .text-color {
        color: #f56c6c;
      }
    }
  }
}

.block {
  border-top: 1px solid #ebedf0;
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}

.footerSelect {
  width: 200px;
}

.lineBar {
  display: flex;
  align-items: center;
  height: 50px;

  .line {
    width: 3px;
    height: 16px;
    background: #06b880;
    margin-right: 10px;
  }
}
</style>
<style lang="scss">
.owner-detail {
  .el-form-item__label {
    color: #606266;
  }

  .el-form-item__content {
    color: #171717;
  }
}

.el-tabs__nav-wrap::after {
  display: none;
}

.el-tabs__item.is-active {
  color: #323233;
}

.el-tabs__active-bar {
  height: 2px;
  background: #06b880;
}

.preview-image {
  margin-right: 10px;
}

</style>
