<template>
  <div class="stock-check-wrapper">
    <el-dialog
      v-model="visible"
      title="库存不足提示"
      width="60%"
      custom-class="stock-check-dialog"
    >
      <PlTable
        :data="data"
        :columns="tableColumns"
      />
      <template #footer>
        <div class="footer">
          <div class="button-wrapper">
            <div>
              <nh-button v-if="isSubImport" @click="handleExportError">
                导出
              </nh-button>
              <nh-button
                type="primary"
                :loading="submiting"
                @click="handleSubmit"
              >
                继续下单
              </nh-button>
              <nh-button @click="handleCancel">
                取 消
              </nh-button>
            </div>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import tableColumns from './fileds';

export default {
  props: {
    submiting: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    // 是否批量导入
    isSubImport: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['onSubmit', 'onExport'],
  data() {
    return {
      visible: false,
      tableColumns,
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    handleSubmit() {
      this.$emit('onSubmit');
    },
    handleCancel() {
      this.close();
    },
    handleExportError() {
      this.$emit('onExport');
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style scoped lang="scss">
.stock-check-wrapper {
  :deep(.stock-check-dialog) {
    width: 60%;
  }
}

.footer {
  padding-top: 20px;
  border-top: 1px solid #ebeef5;

  .button-wrapper {
    text-align: right;
  }
}
</style>
