<template>
  <nh-drawer
    v-model="dialogVisible"
    title="创建波次"
    destroy-on-close
    size="704px"
    :before-close="handleClose"
  >
    <el-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="已选出库单数：" prop="deliverOrderIds">
        <span>{{ form.deliverOrderIds.length }}</span>
      </el-form-item>
      <el-form-item label="波次分组方式：" prop="radio">
        <el-radio-group v-model="form.radio">
          <el-radio :label="true">
            系统默认
          </el-radio>
          <el-radio :label="false">
            自定义分组
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="!form.radio"
        label="自定义分组方式："
        prop="waveGroupType"
      >
        <el-select
          v-model="form.waveGroupType"
          placeholder="--请选择--"
          clearable
        >
          <el-option
            v-for="item in groupTypes"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="备注："
        prop="remark"
        :span="24"
      >
        <el-input
          v-model.trim="form.remark"
          type="textarea"
          :rows="2"
          placeholder=""
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="footer">
        <nh-button
          type="grey"
          @click="handleClose"
        >
          取 消
        </nh-button>
        <nh-button
          type="primary"
          :loading="loading"
          @click="handleSubmitForm"
        >
          确 定
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>
<script setup>
import { ref, unref, reactive } from 'vue';
import { ElMessage } from 'element-plus';
import { waveGroupTypes, waveOrderCreate, customGroupCreate } from '../api';
import {
  WAVE_COMPOSE_TYPE_ENUM,
} from '../constant';

const emits = defineEmits(['onSuccess']);
const ruleForm = ref(null);
const dialogVisible = ref(false);
const rules = reactive({
  waveGroupType: [{
    type: 'string',
    required: true,
    message: '请选择',
    trigger: 'change',
  },
  ],
});
const groupTypes = ref([]);
const form = ref({
  deliverOrderIds: [],
  radio: false,
});
const loading = ref(false);

const init = async (ids = []) => {
  dialogVisible.value = true;
  form.value.deliverOrderIds = ids;
  groupTypes.value = await waveGroupTypes();
};
const handleClose = () => {
  if (ruleForm.value) {
    ruleForm.value.resetFields();
  }
  dialogVisible.value = false;
};

const handleSubmitForm = async () => {
  if (form.value.radio) {
    await waveOrderCreate({
      deliverOrderList: form.value.deliverOrderIds,
      waveComposeType: WAVE_COMPOSE_TYPE_ENUM.BATCH,
      remark: form.value.remark,
    });
  } else {
    await ruleForm.value.validate();
    await customGroupCreate(unref(form));
  }
  ElMessage({
    message: '创建成功',
    type: 'success',
  });
  handleClose();
  emits('onSuccess');
};
defineExpose({
  init,
});
</script>
<style lang="scss" scope>
.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>
