<template>
  <nh-drawer
    v-model="dialogVisible"
    v-loading="loading.loadDeliverOrderDetail"
    :before-close="handleClose"
    destroy-on-close
    title="批量创建波次"
    size="1112px"
    :track="{
      name_zh: '出库管理/出库单管理/抽屉-批量创建波次',
      trackName: $route.path
    }"
  >
    <el-form
      ref="ruleForm"
      :model="headerData"
      label-width="120px"
    >
      <el-form-item label="波次组合方式：" prop="abstractZoneCode">
        <el-radio-group v-model="headerData.abstractZoneCode">
          <el-radio :label="WAVE_COMPOSE_TYPE_ENUM.SHIPPING_ORDER">
            按提货单
          </el-radio>
          <el-radio :label="WAVE_COMPOSE_TYPE_ENUM.CUSTOMER">
            按货主
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div
      v-show="headerData.abstractZoneCode === WAVE_COMPOSE_TYPE_ENUM.SHIPPING_ORDER"
      class="body"
    >
      <el-form
        ref="shippingSearchFrom"
        :model="shippingSearchFrom"
        label-width="120px"
        inline
      >
        <div>
          <el-form-item
            label="预约提货时间"
            prop="time"
            class="width-500"
          >
            <el-date-picker
              v-model="shippingSearchFrom.time"
              style="width: 300px;"
              type="datetimerange"
            />
          </el-form-item>
        </div>
        <div>
          <el-form-item
            label="线路名称"
            prop="lineIds"
            class="width-500"
          >
            <el-select
              v-model="shippingSearchFrom.lineIds"
              style="width: 300px;"
              multiple
              clearable
              collapse-tags
            >
              <el-option
                v-for="item in lineList"
                :key="item.lineId"
                :label="item.lineName"
                :value="item.lineId"
              >
                <span class="mltiple-left">{{ item.lineId }}</span>
                <span class="mltiple-right">{{ item.lineName }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="margin-left: 120px;">
          <el-form-item label="">
            <nh-button type="grey" @click="handleReset">
              重置
            </nh-button>
            <nh-button type="primary" @click="handleShippingSearch">
              查询
            </nh-button>
          </el-form-item>
        </div>
      </el-form>
      <PlTable
        v-model:pagination="shippingPagination"
        :loading="loading.waveOrderPageLadingOrder"
        :data="hippingTableData"
        :columns="TABLECOLUMN"
        @selection-change="handleHippingSelection"
        @update:pagination="handleHippingPaginationChange(shippingPagination)"
      >
        <template #ladingOrderNo="{row}">
          <nh-link
            :to="{}"
            :text="row.ladingOrderNo"
            @click="handlePickupPlanDetail(row)"
          />
        </template>
      </PlTable>
    </div>

    <div
      v-show="headerData.abstractZoneCode === WAVE_COMPOSE_TYPE_ENUM.CUSTOMER"
      class="body customer-body"
    >
      <PlTable
        v-model:pagination="customerPagination"
        :loading="loading.waveOrderPageCustomer"
        :data="customerTableData"
        :columns="CUSTOMER_TABLECOLUMN"
        @selection-change="handleCustomerSelection"
        @update:pagination="handleCustomerPaginationChange(customerPagination)"
      />
    </div>
    <template #footer>
      <div class="footer">
        <nh-button
          type="primary"
          :loading="loading.pageWaveOrderCreate"
          @click="headleSubmitForm"
        >
          创建波次
        </nh-button>
        <nh-button @click="dialogVisible = false">
          取消
        </nh-button>
      </div>
    </template>
    <PickupPlanDetail ref="pickupPlanDetail" />
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import moment from 'moment';
import mixin from '../mixin';
import { TABLECOLUMN, serchFields, CUSTOMER_TABLECOLUMN } from './fileds';
import { waveOrderPageLadingOrder, waveOrderPageCustomer, waveOrderCreate } from '../api';
import { WAVE_COMPOSE_TYPE_ENUM } from '../constant';
import deliverOrderLine from './api';
import PickupPlanDetail from '../../pickup-plan/detail/index.vue';

export default {
  name: 'WaveCreate',
  components: {
    PickupPlanDetail,
  },
  mixins: [loadingMixin, mixin],
  emits: ['createSuccess'],
  data() {
    return {
      TABLECOLUMN,
      serchFields,
      CUSTOMER_TABLECOLUMN,
      WAVE_COMPOSE_TYPE_ENUM,
      headerData: {
        abstractZoneCode: WAVE_COMPOSE_TYPE_ENUM.SHIPPING_ORDER,
      },
      lineList: [],
      dialogVisible: false,
      loading: {
        headleSubmitForm: false,
        waveOrderPageLadingOrder: false,
        waveOrderPageCustomer: false,
        pageWaveOrderCreate: false,
      },
      shippingPagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      hippingTableData: [],
      customerPagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      customerTableData: [],
      shippingSearchFrom: {
        time: '',
        lineIds: [],
      },
      hippingSelection: [],
      customerSelection: [],
      rules: {
        code: [{ required: true, message: '请输入设备编码', trigger: 'blur' }],
      },
    };
  },
  watch: {
    'shippingSearchFrom.time': {
      handler(newValue) {
        if (newValue && newValue instanceof Array) {
          this.shippingPagination.startBookingTime = newValue[0] && newValue[0].valueOf();
          this.shippingPagination.endBookingTime = newValue[1]
            && newValue[1] instanceof Date
            && moment(newValue[1])
              .endOf('day')
              .valueOf();
        } else {
          this.shippingPagination.startBookingTime = '';
          this.shippingPagination.endBookingTime = '';
        }
      },
      deep: true,
    },
  },
  methods: {
    async showWaveCreateDialog() {
      this.dialogVisible = true;
      this.handleReset();
      this.getLineList();
    },
    handleHippingPaginationChange(pagination) {
      const shippingPagination = {
        ...this.shippingPagination,
        ...pagination,
      };
      this.waveOrderPageLadingOrder(shippingPagination);
    },
    handleCustomerPaginationChange(pagination) {
      const customerPagination = {
        ...this.customerPagination,
        ...pagination,
      };
      this.waveOrderPageCustomer(customerPagination);
    },
    async headleSubmitForm() {
      this.waveOrderCreate();
    },
    // 查询所有待组波的线路
    async getLineList() {
      this.lineList = await deliverOrderLine();
    },
    handleReset() {
      this.shippingPagination = {
        page: 1,
        size: 10,
        total: 0,
      };
      this.customerPagination = {
        page: 1,
        size: 10,
        total: 0,
      };
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields();
      }
      if (this.$refs.shippingSearchFrom) {
        this.$refs.shippingSearchFrom.resetFields();
      }
      this.waveOrderPageLadingOrder(this.shippingPagination);
      this.waveOrderPageCustomer(this.customerPagination);
    },
    handleHippingSelection(selection) {
      this.hippingSelection = selection;
    },
    handleCustomerSelection(selection) {
      this.customerSelection = selection;
    },
    handleShippingSearch() {
      this.waveOrderPageLadingOrder(this.shippingPagination);
    },
    close() {
      this.dialogVisible = false;
    },
    async waveOrderPageLadingOrder(pagination) {
      const resp = await waveOrderPageLadingOrder(pagination, {
        ...this.shippingPagination,
        ...this.shippingSearchFrom,
      });
      this.hippingTableData = resp.records;
      this.shippingPagination.total = resp.total;
    },
    async waveOrderPageCustomer(pagination) {
      const resp = await waveOrderPageCustomer(pagination, {});
      this.customerTableData = resp.records;
      this.customerPagination.total = resp.total;
    },
    async pageWaveOrderCreate(data) {
      const result = await waveOrderCreate(data);
      return result;
    },
    async waveOrderCreate() {
      const getOrderIds = (selection) => {
        const arr = selection.map((item) => item.deliverOrderIdList);
        return arr.flat();
      };
      let waveOrderCreateResult = '';
      await this.$confirm('是否对勾选的订单生成波次？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });

      if (this.headerData.abstractZoneCode === WAVE_COMPOSE_TYPE_ENUM.SHIPPING_ORDER) {
        waveOrderCreateResult = await this.pageWaveOrderCreate({
          waveComposeType: this.headerData.abstractZoneCode,
          deliverOrderList: getOrderIds(this.hippingSelection),
        });
      }
      if (this.headerData.abstractZoneCode === WAVE_COMPOSE_TYPE_ENUM.CUSTOMER) {
        waveOrderCreateResult = await this.pageWaveOrderCreate({
          waveComposeType: this.headerData.abstractZoneCode,
          deliverOrderList: getOrderIds(this.customerSelection),
        });
      }
      if (waveOrderCreateResult) {
        this.waveOrderPageLadingOrder(this.shippingPagination);
        this.waveOrderPageCustomer(this.customerPagination);
        this.$message({ type: 'success', message: '创建成功' });
        this.$emit('createSuccess');
      }
    },
    handlePickupPlanDetail(row) {
      this.$refs.pickupPlanDetail.pickupPlanDetail(row.ladingOrderId);
    },
  },
};
</script>
<style lang="scss" scoped>

.header {
  padding-bottom: 22px;
}

.body-wrap {
  height: 450px;
  width: 100%;
}

.body {
  height: 450px;
  padding-bottom: 24px;
}

.width-500 {
  width: 500px;
}

.mltiple-left {
  float: left;
}

.mltiple-right {
  float: right;
  padding-left: 8px;
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}

</style>
