<template>
  <nh-drawer
    v-model="drawerVisible"
    v-loading="loading.loadDeliverOrderDetail"
    :before-close="handleClose"
    destroy-on-close
    :title="`${isEdit ? '编辑' : '新增'}出库单`"
    size="1111px"
    :track="{
      name_zh: `出库管理/${nameZh}/抽屉-${isEdit ? '编辑' : '新增'}`,
      trackName: $route.path
    }"
  >
    <el-form
      ref="ruleForm"
      :model="form"
      label-width="120px"
      :rules="rules"
      label-position="top"
      @submit.prevent
    >
      <nh-drawer-container title="订单基本信息">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item
                  label="所属货主"
                  prop="customer"
                >
                  <el-input
                    v-if="isEdit"
                    v-model="form.customer.shipperName"
                    disabled
                  />
                  <nh-shipper-selector
                    v-else
                    v-model:shipper="form.customer"
                    class="width-100"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  ref="deliverType"
                  label="出库类型"
                  prop="deliverType"
                >
                  <el-select
                    v-model="form.deliverType"
                    placeholder="请选择出库类型"
                    class="width-100"
                    :disabled="isEdit"
                    @change="handleGetMatchOne"
                  >
                    <el-option
                      v-for="item in DELIVER_TYPE"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item
                  ref="pickupType"
                  label="提货方式"
                  prop="pickupType"
                >
                  <el-select
                    v-model="form.pickupType"
                    placeholder="请选择提货方式"
                    class="width-100"
                    :disabled="isEdit"
                  >
                    <el-option
                      v-for="item in PICKUP_TYPE"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item
                  ref="bookingTime"
                  label="预约提货时间"
                  prop="bookingTime"
                >
                  <el-date-picker
                    v-model="form.bookingTime"
                    style="width: 100%;"
                    :disabled-date="bookingTimeOptions"
                    :default-value="defaultValue"
                    type="datetime"
                    :disabled="isEdit"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  ref="station"
                  label="站点"
                  prop="station"
                >
                  <nh-remote-select
                    v-model="form.station"
                    reserve-keyword
                    :remote-method="handleSearchStation"
                    :loading="loading.loadStationList"
                    class="width-100"
                    value-key="id"
                  >
                    <el-option
                      v-for="item in stationOptions"
                      :key="item.id"
                      :label="item.stationName"
                      :value="item"
                    />
                  </nh-remote-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="配销分类"
                  prop="distributeTypeCode"
                >
                  <el-select
                    v-model="form.distributeTypeCode"
                    placeholder="请选择提货方式"
                    class="width-100"
                    clearable
                  >
                    <el-option
                      v-for="item in DISTRIBUTION"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  ref="handling"
                  label="是否需要装卸"
                  prop="handling"
                >
                  <el-select
                    v-model="form.handling"
                    placeholder="请选择"
                    @change="handlingChange"
                  >
                    <el-option
                      v-for="item in URGENT"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="form.handling" :span="8">
                <el-form-item
                  ref="outsourceHandling"
                  label="是否外包装卸"
                  prop="outsourceHandling"
                >
                  <el-select
                    v-model="form.outsourceHandling"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in URGENT"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  ref="stationContact"
                  label="站点联系人"
                  prop="stationContact"
                >
                  <el-input v-model="form.stationContact" disabled />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item
                  ref="stationTelephone"
                  label="联系人电话"
                  prop="stationTelephone"
                >
                  <el-input v-model="form.stationTelephone" disabled />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item
                  ref="stationAddress"
                  label="站点地址"
                  prop="stationAddress"
                >
                  <el-input v-model="form.stationAddress" disabled />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item
                  ref="remark"
                  label="备注"
                  prop="remark"
                >
                  <el-input
                    v-model="form.remark"
                    type="textarea"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="" prop="files">
              <nh-upload-attachment
                v-model:upload-file="fileData"
                :uploads-style="{
                  padding: '12px 24px',
                  backgroundColor: '#f6f8fa',
                  borderRadius: '4px',
                }"
                @onRemove="handleFileRemove"
                @onAllRemove="handleAllRemove"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </nh-drawer-container>
    </el-form>
    <nh-drawer-container>
      <template #title>
        <div class="drawer-container-goods-title">
          <span>货品信息</span>
          <nh-button
            class="add-goods"
            type="primary"
            @click="handleShowGoodDialog"
          >
            添加货品
          </nh-button>
        </div>
      </template>

      <AddGood
        ref="addGood"
        :title="isEdit ? '编辑' : '新增'"
        :shipper-id="shipperId"
        :added-goods="goods"
        @added="handleAddGoods"
      />
      <AddedGoods
        :goods="goods"
        @selectBatchAttr="handleSelectBatchAttr"
        @removeGood="handleRemoveGood"
        @updateGoodBookingNum="handleUpdateGoodBookingNum"
        @updateQuality="updateQuality"
      />
    </nh-drawer-container>

    <template #footer>
      <div class="footer">
        <nh-button
          type="grey"
          @click="handleClose"
        >
          关闭
        </nh-button>
        <nh-button
          type="primary"
          :loading="loading.deliverOrderSave"
          @click="handleSaveDeliverOrder"
        >
          保存
        </nh-button>
      </div>
    </template>
    <stockCheckDialog
      ref="outOfStok"
      :data="tableData"
      :is-sub-import="false"
      :submiting="loading.handleSaveStock"
      @onSubmit="handleContinue"
    />
  </nh-drawer>
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import utils from '@/utils/unitNumber';
import { CROSS_DOCKING_CONVERT } from '@/constant/crossDocking';
import { YMDHMS } from '@/constant/timeFormat';
import mixin from './mixin';
import {
  DELIVER_TYPE, PICKUP_TYPE, TEMPERATURE_LAYER_TO_NEW_ENUM, URGENT,
} from './constant';
import {
  stationList,
  deliverOrderSave,
  deliverOrderDetail,
  deliverOrderEdit,
  deliverFlowMatchOne,
  checkDeliverOrderBeforeSave,
  checkDeliverOrderBeforeEdit,
} from './api';
import AddGood from './addGood/index.vue';
import AddedGoods from './addedGoods/index.vue';
import stockCheckDialog from './subpages/stockCheckDialog/index.vue';

export default {
  name: 'DeliverOrderCreate',
  components: { AddGood, AddedGoods, stockCheckDialog },
  mixins: [loadingMixin, mixin],
  inject: ['nameZh'],
  emits: ['handleSave'],
  data() {
    return {
      DELIVER_TYPE,
      PICKUP_TYPE,
      URGENT,
      shipperId: '',
      drawerVisible: false,
      goods: [],
      loading: {
        loadStationList: false,
        loadShipperList: false,
        deliverOrderSave: false,
        loadDeliverOrderDetail: false,
        handleSaveStock: false,
      },
      form: {
        customer: {},
        shipperCode: '',
        shipperName: undefined,
        deliverType: undefined,
        externalOrderNo: undefined,
        pickupType: undefined,
        bookingTime: moment(),
        station: {},
        stationName: undefined,
        stationContact: undefined,
        stationTelephone: undefined,
        stationAddress: undefined,
        remark: undefined,
        handling: undefined,
        outsourceHandling: undefined,
        files: [],
      },
      rules: {
        shipperCode: [
          { required: true, message: '请输入货主编码', trigger: 'blur' },
        ],
        shipperName: [
          { required: true, message: '请输入货主名称', trigger: 'blur' },
        ],
        deliverType: [
          { required: true, message: '请选择出库类型', trigger: 'change' },
        ],
        externalOrderNo: [
          { required: true, message: '请输入外部单号', trigger: 'blur' },
        ],
        pickupType: [
          { required: true, message: '请输入提货方式', trigger: 'blur' },
        ],
        bookingTime: [
          { required: true, message: '请选择预约提货时间', trigger: 'change' },
        ],
        station: [
          { required: true, message: '请输入站点', trigger: 'blur' },
        ],
        stationContact: [
          { required: false, message: '请输入站点联系人', trigger: 'blur' },
        ],
        stationTelephone: [
          { required: false, message: '请选择站点联系人电话', trigger: 'change' },
        ],
        stationAddress: [
          { required: false, message: '请输入站点地址', trigger: 'blur' },
        ],
        remark: [
          { required: false, message: '请输入备注', trigger: 'blur' },
        ],
        files: [
          { required: false, message: '请选择附件', trigger: 'change' },
        ],
      },
      orderId: '',
      stationOptions: [],
      customerOptions: [],
      bookingTimeOptions: (time) => time < moment().subtract(1, 'days').valueOf(),
      fileData: [],
      defaultQuality: null,
      isEdit: false,
      defaultValue: moment(),
      tableData: [],
    };
  },
  watch: {
    'form.station': {
      handler(newValue) {
        this.form.stationName = newValue.stationName;
        this.form.stationId = newValue.id;
        this.form.stationCode = newValue.stationCode;
        this.form.stationContact = newValue.contacts;
        this.form.stationTelephone = newValue.telPhone;
        this.form.stationAddress = newValue.address;
        this.form.stationCity = newValue.cityName;
      },
      deep: true,
    },
    'form.customer': {
      handler(newValue) {
        if (!this.orderId) {
          this.form.shipperCode = newValue.shipperCode || newValue.customerCode;
          this.form.shipperName = newValue.shipperName || newValue.customerName;
          this.form.shipperId = newValue.shipperId;
          this.shipperId = newValue.shipperId;
          this.form.handling = newValue.baseRuleExt?.deliverOrderHandling;
          this.form.outsourceHandling = newValue.baseRuleExt?.deliverOrderHandling
            ? true : undefined;
          this.clearPageData();
          this.loadStationList('');
        }
      },
      deep: true,
    },
  },
  methods: {
    init(id) {
      if (id) {
        this.loadDeliverOrderDetail(id);
        this.orderId = id;
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
      this.drawerVisible = true;
    },
    async handleGetMatchOne(val) {
      try {
        const { configOrderAudit = {} } = await deliverFlowMatchOne({ deliverType: val });
        const [defaultQuality] = configOrderAudit.goodsQuality || [];
        this.defaultQuality = defaultQuality;
      } catch (error) {
        this.defaultQuality = null;
      }
      this.goods = this.goods.map((item) => ({
        ...item,
        quality: this.defaultQuality,
      }));
    },
    handlingChange(val) {
      if (val === URGENT[0].value) {
        this.form.outsourceHandling = URGENT[0].value;
      } else {
        this.form.outsourceHandling = undefined;
      }
    },
    fileNameRemoveTime(fileName) {
      const fileNames = `${fileName}` ? fileName.split('_') : [];
      // 上传时是使用下划线_ 区分时间戳和文件名的，展示时 去掉时间戳
      return fileNames.filter((item, index) => index > 0).join('_');
    },
    handleShowGoodDialog() {
      this.$refs.addGood.showDialog(this.shipperId);
    },
    handleReset() {
      this.$refs.ruleForm.resetFields();
    },
    async handleSearchStation(query) {
      this.loadStationList(query);
    },
    handleAddGoods(selectGoods) {
      // 以追加的形式添加
      this.goods = [...this.goods, ...selectGoods.map((item) => ({
        ...item,
        goodsId: item.id,
        spec: item.goodsSpecifications,
        // 1.0 越库转2.0
        crossDocking: CROSS_DOCKING_CONVERT[item.isOverWarehouse],
        // 添加默认货品质量
        quality: this.defaultQuality,
        // 老温层转换为新的温层
        temperatureLayer: item.temperatureLayerCode ? TEMPERATURE_LAYER_TO_NEW_ENUM[item.temperatureLayerCode - 0] : '',
      }))];
    },
    handleSelectBatchAttr(rowData, rowIndex) {
      this.goods = this.goods.map((item, index) => {
        if (item.id === rowData.goodsId && rowIndex === index) {
          return {
            ...item,
            batchNo: rowData.batchNo,
            storehouseId: rowData.locationId,
            storehouseCode: rowData.locationCode,
          };
        }
        return item;
      });
    },
    async handleSaveDeliverOrder() {
      this.dialogVisible = true;
      const judgeGoodsIsRepeat = (goods) => {
        const noRepeatGoodIds = [];
        const errorInfo = [];
        goods.forEach((item) => {
          const batchNo = item.batchNo ? item.batchNo : '';
          const quality = item.quality ? item.quality : '';
          const storehouseCode = item.storehouseCode ? item.storehouseCode : '';
          const repeatGoodKey = `${item.goodsId}-${batchNo}-${quality}-${storehouseCode}`;
          if (noRepeatGoodIds.includes(repeatGoodKey)) {
            errorInfo.push(item.goodsName);
          } else {
            noRepeatGoodIds.push(repeatGoodKey);
          }
        });
        // 使用reduce 将数组转化为object 利用 objectkey唯一的特性去重，再将object Key转化为array以逗号作为分隔符
        return Object.keys(errorInfo.reduce((total, item) => ({ ...total, [item]: 1 }), {})).join(',');
      };
      // 货品数量不能小于0
      const bookingFlag = this.goods.every((item) => item.bookingNum > 0);
      // 辅助单位数量不能小于0 且为抄码
      const auxiliaryFlag = this.goods.every((item) => {
        if (item.isCopyCode !== 0 && item.isCopyCode !== null) {
          return item.bookingAuxiliaryAmount > 0;
        }
        return true;
      });
      if (bookingFlag && auxiliaryFlag) {
        const repeatResult = judgeGoodsIsRepeat(this.goods);
        if (!repeatResult) {
          if (this.orderId) {
            // 编辑
            this.checkDeliverOrderEdit();
          } else if (moment().isSameOrBefore(this.form.bookingTime, 'day')) {
            const { ...formData } = this.form;
            // 新增
            this.deliverOrderSave({
              ...formData,
              goods: this.goods,
              files: this.fileData,
              station: undefined,
            });
          } else {
            this.$message({ type: 'warning', message: '请选择当前时间之后的预约提货时间' });
          }
        } else {
          this.$message({ type: 'warning', message: '同一个商品不能指定同一个批次号、质量或库位' });
        }
      } else if (!bookingFlag) {
        this.$message({ type: 'warning', message: '已添加货品数量不能小于0' });
      } else {
        this.$message({ type: 'warning', message: '已添加辅助单位数量不能小于0' });
      }
    },

    handleRemoveGood(rowData, rowIndex) {
      // 用filter 代替删除动作

      this.goods = this.goods.filter((item, index) => index !== rowIndex);
    },
    handleUpdateGoodBookingNum(updateRow) {
      // 利用map 实现更新列表内某项字段
      this.goods = this.goods.map((item, index) => {
        // 当货品ID，已添加货品序号，才确认为同一条数据
        if (updateRow.id === item.id && index === updateRow.rowIndex) {
          return {
            ...item,
            [updateRow.field]: updateRow.num,
          };
        }
        return item;
      });
    },
    updateQuality(updateRow) {
      this.goods = this.goods.map((item, index) => {
        // 当货品ID，已添加货品序号，才确认为同一条数据
        if (updateRow.id === item.id && index === updateRow.rowIndex) {
          return {
            ...item,
            quality: updateRow.quality,
          };
        }
        return item;
      });
    },
    handleFileRemove({ index }) {
      // 删除附件
      this.fileData = this.fileData.filter((item, i) => i !== index);
    },
    handleAllRemove() {
      this.fileData = [];
    },
    // 选择货主时清空 页面数据
    clearPageData() {
      this.$refs.deliverType.resetField();
      this.$refs.pickupType.resetField();
      this.$refs.bookingTime.resetField();
      this.$refs.stationContact.resetField();
      this.$refs.stationTelephone.resetField();
      this.$refs.stationAddress.resetField();
      this.$refs.remark.resetField();
      this.$refs.station.resetField();
      this.stationOptions = [];
      this.fileData = [];
      this.goods = [];
    },
    async loadStationList(query) {
      const result = await stationList({ nameLike: query, shipperCode: this.form.shipperCode });
      this.stationOptions = result;
    },
    async deliverOrderSave(data) {
      const params = {
        ...data,
        tenantId: undefined,
        tenantName: undefined,
        useAvailableAmount: false, // 库存不足不可以保存
      };

      // 提交前校验出库单库存
      await this.checkDeliverOrderAdded(params);
    },
    // 校验新增出库单库存
    async checkDeliverOrderAdded(params) {
      // errorMsg：缺库数据
      const { errorMsg } = await checkDeliverOrderBeforeSave(params); // 缺货数据在这里返回

      if (errorMsg.length) {
        this.$refs.outOfStok.open();
        this.tableData = errorMsg;
      } else {
        this.addDeliverOrder({
          ...params,
          useAvailableAmount: false, // 不使用库存数量
        });
      }
    },
    handleCancel() {
      this.tableData = [];
    },
    // 缺货下单继续
    handleContinue() {
      this.loading.handleSaveStock = true;
      if (this.orderId) {
        this.deliverOrderEdit();
      } else {
        // 新增
        const { ...formData } = this.form;
        const params = {
          ...formData,
          goods: this.goods,
          files: this.fileData,
          station: undefined,
          tenantId: undefined,
          tenantName: undefined,
          useAvailableAmount: false, // 库存不足不可以保存
        };
        this.addDeliverOrder(params);
      }
    },
    async addDeliverOrder(params) {
      try {
        const result = await deliverOrderSave(params);
        if (result && result.success) {
          this.$message({
            message: '保存成功',
            type: 'success',
          });
          this.handleClose();
          this.$emit('handleSave');
        } else {
          this.loading.handleSaveStock = false;
        }
      } catch (error) {
        this.loading.handleSaveStock = false;
      }
    },
    // 编辑前校验是否缺库
    async checkDeliverOrderEdit() {
      const params = this.getEditParams();
      const { errorMsg } = await checkDeliverOrderBeforeEdit(params);
      if (errorMsg?.length) {
        this.$refs.outOfStok.open();
        this.tableData = errorMsg;
      } else {
        this.deliverOrderEdit();
      }
    },
    async deliverOrderEdit() {
      try {
        const params = this.getEditParams();
        await deliverOrderEdit(params);
        this.$message({
          message: '修改成功',
          type: 'success',
        });
        this.handleClose();
        this.$emit('handleSave');
      } catch (error) {
        this.loading.handleSaveStock = false;
      }
    },
    getEditParams() {
      const {
        stationAddress,
        stationCode,
        stationContact,
        stationId,
        stationName,
        stationTelephone,
        handling,
        outsourceHandling,
        stationCity,
        distributeTypeCode,
      } = this.form;
      return {
        tenantId: undefined,
        tenantName: undefined,
        id: this.orderId,
        stationAddress,
        stationCode,
        stationContact,
        stationId,
        stationName,
        stationTelephone,
        remark: this.form.remark,
        handling,
        outsourceHandling,
        goods: this.goods,
        files: this.fileData,
        stationCity,
        distributeTypeCode,
      };
    },
    async loadDeliverOrderDetail(id) {
      const result = await deliverOrderDetail({ id });
      this.form.customer.shipperName = result.shipperName;
      this.form.shipperCode = result.shipperCode;
      this.form.deliverType = result.deliverType;
      this.form.pickupType = result.pickupType;
      this.form.bookingTime = result.bookingTime || this.defaultValue;
      this.form.station.stationName = result.stationName;
      this.form.station.id = result.stationId;
      this.form.station.stationCode = result.stationCode;
      this.form.station.contacts = result.stationContact;
      this.form.station.telPhone = result.stationTelephone;
      this.form.station.address = result.stationAddress;
      this.form.remark = result.remark;
      this.form.handling = result.handling;
      this.form.outsourceHandling = result.outsourceHandling;
      this.form.distributeTypeCode = result.distributeTypeCode;
      try {
        await this.loadStationList(result.stationName);
      } finally {
        this.form.stationCity = result.stationCity;
      }
      this.$nextTick(() => {
        this.shipperId = result.shipperId;
        this.goods = result.goods.map((item) => {
          const total = utils.calculate({
            smallUnitNumber: item.bookingNum,
            bigUnitNumber: 0,
            conversionRate: item.conversionRate,
          });
          return {
            ...item,
            bookingNumBig: total.bigUnitNumber,
            bookingNumSmall: total.smallUnitNumber,
            id: item.goodsId,
            deliverOrderId: item.id,
          };
        });
      });

      this.fileData = result.fileAttachments.map((item) => ({
        ...item,
        fileTime: moment(item.createTime).format(YMDHMS),
      }));
    },
    handleClose() {
      this.drawerVisible = false;
      this.loading.handleSaveStock = false;
      this.form = {
        customer: {},
        shipperCode: '',
        shipperName: undefined,
        deliverType: undefined,
        externalOrderNo: undefined,
        pickupType: undefined,
        bookingTime: undefined,
        station: {},
        stationName: undefined,
        stationContact: undefined,
        stationTelephone: undefined,
        stationAddress: undefined,
        remark: undefined,
        handling: undefined,
        outsourceHandling: undefined,
        files: [],
      };
      this.goods = [];
      this.stationOptions = [];
      this.customerOptions = [];
      this.orderId = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.drawer-container-goods-title {
  span {
    margin-right: 24px;
  }
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}

.basic-info {
  background: #fff;
  box-shadow: 0 1px 6px 0 rgba(0, 25, 55, 0.1);
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 16px 24px;
}

.file-show-box {
  padding: 12px 24px;
  background-color: #f6f8fa;
  border-radius: 4px;
  width: 86%;

  .file-rows {
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .file-info {
      & > div {
        height: 24px;
      }

      .file-name {
        color: #606266;
        font-size: 14px;
      }

      .file-upload-time {
        font-size: 14px;
        color: #909399;
      }
    }

    .file-opera {
      .text-color {
        color: #f56c6c;
      }
    }
  }
}

.width-100 {
  width: 100%;
}

.margin-left {
  margin-left: 4px;
}

.margin-bottom {
  margin-bottom: 24px;
}

.margin-right {
  margin-right: 24px;
}

.padding-top {
  padding-top: 12px;
}
</style>
