import { FormType } from '@/constant/form';
import unitNumber from '@/utils/unitNumber';

function TABLECOLUMN(dictionary = []) {
  const dynamicCol = dictionary
    .map((item) => ({ label: item.label, prop: item.value, width: 180 }));
  return [{
    label: '',
    prop: 'radio',
    width: 40,
  }, {
    label: '序号',
    type: 'index',
    width: 60,
  }, {
    label: '批次号',
    prop: 'batchNo',
    width: 180,
  }, ...dynamicCol, {
    label: '可用库存量',
    prop: 'bigAndSmallUnitNumStr',
    width: 180,
  }];
}
export {
  TABLECOLUMN,
};
export const TABLECOLUMN_STOREHOUSE = [
  {
    label: '',
    prop: 'radio',
    width: 40,
  }, {
    label: '序号',
    type: 'index',
    width: 60,
  }, {
    label: '库位号',
    prop: 'locationCode',
    minWidth: 100,
  }, {
    label: '可用库存量',
    prop: 'amount',
    minWidth: 180,
    formatter: (row) => unitNumber.calculate({ ...row, smallUnitNumber: row.amount }).txt,
  },
];
export function serchFields() {
  return [
    {
      label: '货品编码',
      prop: 'storageLocationCode',
      type: FormType.INPUT,
    }, {
      label: '货品名称',
      prop: 'col',
      type: FormType.INPUT,
    }, {
      label: '指定类型',
      prop: 'warehouseAreaId',
      type: FormType.SELECT,
      options: [],
    }, {
      label: '',
      prop: 'warehouseAreaId',
      type: FormType.SELECT,
      options: [],
    },
  ];
}
