<template>
  <div>
    <el-table
      class="flex_1 width-100"
      :data="goods"
      height="400px"
      border
      :show-overflow-tooltip="true"
    >
      <el-table-column
        type="index"
        label="序号"
        width="50"
      />
      <el-table-column
        v-for="item in TABLECOLUMN"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :type="item.type"
        :min-width="item.width"
        show-overflow-tooltip
      >
        <template #default="scope">
          <div v-if="item.prop === 'bookingNumBig'">
            <el-input-number
              v-model="scope.row.bookingNumBig"
              class="width-100px"
              size="mini"
              :min="0"
              placeholder="请输入"
              :controls="false"
              :step="1"
              :precision="0"
              step-strictly
              @change="
                currentValue => {
                  handleBookingNumCount(currentValue, scope.row, scope.$index);
                }
              "
            />
            {{ scope.row.bigUnit }}
          </div>
          <div v-else-if="item.prop === 'bookingNumSmall'">
            <el-input-number
              v-model="scope.row.bookingNumSmall"
              :min="0"
              step-strictly
              class="width-100px"
              size="mini"
              :controls="false"
              placeholder="请输入"
              :step="0.001"
              :precision="3"
              @change="
                currentValue => {
                  handleBookingNumCountSmall(currentValue, scope.row, scope.$index);
                }
              "
            />
            {{ scope.row.smallUnit }}
          </div>
          <div v-else-if="item.prop === suppUnits">
            <el-input-number
              v-model="scope.row[suppUnits]"
              :disabled="scope.row.isCopyCode === 0 || scope.row.isCopyCode === null"
              :min="0"
              step-strictly
              class="width-100px"
              size="mini"
              :controls="false"
              placeholder="请输入"
              :precision="2"
              :step="0.01"
            />
            {{ scope.row.auxiliaryUnits }}
          </div>
          <div v-else-if="item.prop === QUALITY">
            <el-select
              v-model="scope.row.quality"
              placeholder="请选择"
              @change="
                currentValue => {
                  handleQuality(currentValue, scope.row, scope.$index);
                }
              "
            >
              <el-option
                v-for="option in GOODS_QUALITY"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </div>
          <span v-else-if="item.ENUM">{{ item.ENUM[scope.row[item.prop]] }}</span>
          <span v-else>{{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
      <!-- 操作栏 -->
      <nh-table-action-column width="150">
        <template #default="scope">
          <nh-button type="text" @click="handleRemove(scope.row, scope.$index)">
            删除
          </nh-button>
          <nh-button type="text" @click="handleStoreSet(scope.row, scope.$index)">
            指定库存
          </nh-button>
        </template>
      </nh-table-action-column>
      <!-- / 操作栏 -->
    </el-table>
    <AddBatch ref="addBatch" @selectBatchAttr="handleSelectBatchAttr" />
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import unitNumber from '@/utils/unitNumber';
import TABLECOLUMN, { QUALITY, suppUnits } from './fileds';
import AddBatch from '../addBatch/index.vue';

export default {
  name: 'AddGoods',
  components: { AddBatch },
  filters: {
    filterLabel(val, arr) {
      const filterArr = arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
  },
  mixins: [loadingMixin],
  props: {
    goods: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['removeGood', 'selectBatchAttr', 'updateGoodBookingNum', 'updateGoodBookingNum', 'updateQuality'],
  data() {
    return {
      TABLECOLUMN,
      QUALITY,
      suppUnits,
      tableData: [],
      waitAddBathRowIndex: 0,
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  created() {},
  mounted() {},
  methods: {
    async handleRemove(rowData, rowIndex) {
      await this.$confirm('你确定要删除该货品吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
      });
      this.$emit('removeGood', rowData, rowIndex);
    },
    handleSelectBatchAttr(rowData) {
      this.$emit('selectBatchAttr', rowData, this.waitAddBathRowIndex);
    },
    handleStoreSet(rowData, rowIndex) {
      this.waitAddBathRowIndex = rowIndex;
      this.$refs.addBatch.init(rowData);
    },
    handleBookingNumCountSmall(currentValue, rowData, rowIndex) {
      const bookingNumBig = rowData.bookingNumBig || 0;
      // 防止 计算结果为NAN
      const currentValueNumber = currentValue || 0;
      const result = unitNumber.getTotal({
        smallUnitNumber: currentValueNumber,
        bigUnitNumber: bookingNumBig,
        conversionRate: rowData.conversionRate,
      });
      this.$emit('updateGoodBookingNum', {
        field: 'bookingNum',
        num: result,
        id: rowData.id,
        rowIndex,
        rowData,
      });
    },
    handleBookingNumCount(currentValue, rowData, rowIndex) {
      const bookingNumSmall = rowData.bookingNumSmall || 0;
      const currentValueNumber = currentValue || 0;
      const result = unitNumber.getTotal({
        smallUnitNumber: bookingNumSmall,
        bigUnitNumber: currentValueNumber,
        conversionRate: rowData.conversionRate,
      });
      this.$emit('updateGoodBookingNum', {
        field: 'bookingNum',
        num: result,
        id: rowData.id,
        rowIndex,
        rowData,
      });
    },
    handleQuality(currentValue, rowData, rowIndex) {
      this.$emit('updateQuality', {
        quality: currentValue,
        id: rowData.id,
        rowIndex,
        rowData,
      });
    },
  },
};
</script>
<style scoped>
.width-100 {
  width: 100%;
}

.width-100px {
  width: 100px;
}
</style>
