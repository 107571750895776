import { FormType } from '@/constant/form';
import { timeFormat } from '../utils';

export const TABLECOLUMN = [
  {
    type: 'selection',
    width: 55,
  }, {
    label: '序号',
    type: 'index',
    width: 50,
  },
  {
    label: '提货单号',
    prop: 'ladingOrderNo',
    minWidth: 120,
  }, {
    label: '车辆信息',
    prop: 'trafficInfo',
    minWidth: 120,
  }, {
    label: '线路编码',
    prop: 'lineId',
    width: 120,
  }, {
    label: '线路名称',
    prop: 'lineName',
    minWidth: 120,
  }, {
    label: '订单数',
    prop: 'orderNum',
    width: 120,
  }, {
    label: '创建时间',
    prop: 'createTime',
    minWidth: 120,
    formatter: ({ createTime }) => timeFormat(createTime),
  }, {
    label: '预约提货时间',
    prop: 'bookingTime',
    minWidth: 120,
    formatter: ({ bookingTime }) => timeFormat(bookingTime),
  }];

export const serchFields = [
  {
    label: '预约提货时间',
    prop: 'timeRange',
    componentType: FormType.DATE_PICKER,
    type: 'daterange',
  },
];
export const CUSTOMER_TABLECOLUMN = [{
  type: 'selection',
  width: 55,
}, {
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货主编号',
  prop: 'shipperCode',
  minWidth: 120,
}, {
  label: '货主名称',
  prop: 'shipperName',
  minWidth: 120,
}, {
  label: '待组波订单数',
  prop: 'orderNum',
  minWidth: 120,
}];
